const local = {
	API_ENDPOINT_URL: 'http://localhost:8000/api',
	API_PUBLIC_URL: 'http://localhost:8000',
	API_ASSET_URL: 'http://localhost:8000',
	WEBSOCKETS_HOST: 'localhost',
	WEBSOCKETS_PORT: '6001',
	WEBSOCKETS_TLS: false,
	PRINTER_URL: 'http://localhost:7485/print',
};

const dev = {
	API_ENDPOINT_URL: 'https://dev.api.platto.cloud/api',
	API_PUBLIC_URL: 'https://dev.api.platto.cloud',
	API_ASSET_URL: 'https://dev.api.platto.cloud',
	WEBSOCKETS_HOST: 'oyster-app-mgwj6.ondigitalocean.app',
	WEBSOCKETS_PORT: '443',
	WEBSOCKETS_TLS: true,
	PRINTER_URL: 'http://localhost:7485/print',
};

const prod = {
	API_ENDPOINT_URL: 'https://api.platto.cloud/api',
	API_PUBLIC_URL: 'https://api.platto.cloud',
	API_ASSET_URL: 'https://api.platto.cloud',
	WEBSOCKETS_HOST: 'oyster-app-mgwj6.ondigitalocean.app',
	WEBSOCKETS_PORT: '443',
	WEBSOCKETS_TLS: true,
	PRINTER_URL: 'http://localhost:7485/print',
};

const test = {
	API_ENDPOINT_URL: '/api'
};

// Helper function to detect if running locally
const isLocalHost = () => {
	const localHosts = ['localhost', '127.0.0.1'];
	return localHosts.includes(window.location.hostname);
};

const getEnv = () => {
	if (isLocalHost()) {
		return local;
	}

	switch (process.env.REACT_APP_ENV) {
		case 'development':
			return dev;
		case 'production':
			return prod;
		case 'test':
			return test;
		default:
			return local;
	}
};

export const env = getEnv();
