import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { clearActiveSession, setActiveSession, clearActiveRegister, clearOrderTypes } from 'store/slices/orderSlice';
import { useNavigate } from 'react-router-dom';
import { REGISTER_SESSION } from 'constants/AuthConstant';
import { API_ENDPOINT_URL, APP_PREFIX_PATH } from 'configs/AppConfig';

const useSessionSync = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const channel = new BroadcastChannel(REGISTER_SESSION);


    useEffect(() => {
        const handleClearSession = () => {
            dispatch(setActiveSession(false));
            dispatch(clearActiveSession());
            dispatch(clearActiveRegister());
            dispatch(clearOrderTypes());
            navigate(API_ENDPOINT_URL + '/sales/registers/index');
        };

        // Listen for CLEAR_SESSION messages
        channel.onmessage = (event) => {
            if (event.data.type === 'CLEAR_SESSION') {
                handleClearSession();
            }
        };

        return () => {
            channel.close();
        };
    }, [dispatch, navigate]);

    const broadcastClearSession = () => {
        channel.postMessage({ type: 'CLEAR_SESSION' });
    };

    return { broadcastClearSession };
};

export default useSessionSync;
