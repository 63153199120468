import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  viewOrder: false,
  newOrder: false,
  changeOrder: false,
  registers: [],
  activeSession: false,
  activeRegister: {},
  sessionID: '',
  orderTypes: [],
  orderType: '',
  orders: [],
  ordersHistory: [],
  selectedOrder: {
    order_comment: '',
  },
  orderCounter: 0,
  orderTable: [],
  floors: {},
  denominations: [],
  salesHistory: [],
  cashInOut: [],
  pendingSave: false,
  currentSelectedTableRow: null
};

const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    setCurrentSelectedTableRow: (state, action) => {
      state.currentSelectedTableRow = action.payload;
    },
    clearCurrentSelectedTableRow: (state) => {
      state.currentSelectedTableRow = null;
    },
    setPendingSave: (state, action) => {
      state.pendingSave = action.payload;
    },
    setCashInOut: (state, action) => {
      state.cashInOut = action.payload;
    },
    clearCashInOut: (state) => {
      state.cashInOut = [];
    },
    setSalesHistory: (state, action) => {
      state.salesHistory = action.payload;
    },
    clearSalesHistory: (state) => {
      state.salesHistory = [];
    },
    setDenominations: (state, action) => {
      state.denominations = action.payload;
    },
    clearDenominations: (state) => {
      state.denominations = [];
    },
    setActiveSession: (state, action) => {
      state.activeSession = action.payload;
    },
    clearActiveSession: (state) => {
      state.activeSession = false;
    },
    setActiveRegister: (state, action) => {
      state.activeRegister = action.payload;
    },
    clearActiveRegister: (state) => {
      state.activeRegister = {};
    },
    setFloors: (state, action) => {
      state.floors = action.payload;
    },
    clearFloors: (state) => {
      state.floors = {};
    },
    setOrderTable: (state, action) => {
      state.orderTable = action.payload;
    },
    clearOrderTable: (state) => {
      state.orderTable = [];
    },
    setViewOrder: (state, action) => {
      state.viewOrder = action.payload;
    },
    clearViewOrder: (state) => {
      state.viewOrder = false;
    },
    setOrders: (state, action) => {
      state.orders = action.payload;
    },
    clearOrders: (state) => {
      state.orders = [];
    },
    setOrdersHistory: (state, action) => {
      state.ordersHistory = action.payload;
    },
    clearOrdersHistory: (state) => {
      state.ordersHistory = [];
    },
    setNewOrder: (state, action) => {
      state.newOrder = action.payload;
    },
    clearNewOrder: (state) => {
      state.newOrder = false;
    },
    setChangeOrder: (state, action) => {
      state.changeOrder = action.payload;
    },
    clearChangeOrder: (state) => {
      state.changeOrder = false;
    },
    setRegisters: (state, action) => {
      state.registers = action.payload;
    },
    clearRegisters: (state) => {
      state.registers = [];
    },
    setSessionID: (state, action) => {
      state.sessionID = action.payload;
    },
    clearSessionID: (state) => {
      state.sessionID = '';
    },
    setOrderTypes: (state, action) => {
      state.orderTypes = action.payload;
    },
    clearOrderTypes: (state) => {
      state.orderTypes = [];
    },
    setOrderType: (state, action) => {
      state.orderType = action.payload;
    },
    clearOrderType: (state) => {
      state.orderType = '';
    },
    setSelectedOrder: (state, action) => {
      state.selectedOrder = action.payload;
    },
    updateOrderComment: (state, action) => {
      state.selectedOrder.order_comment = action.payload; // Update only the comment
    },
    clearSelectedOrder: (state) => {
      state.selectedOrder = {};
    },
    incrementOrderCounter: (state) => {
      state.orderCounter += 1;
    },
    addProductToOrder: (state, action) => {
      const product = action.payload;
      state.selectedOrder.items.push(product);
      state.selectedOrder.subTotal += product.unitPrice * product.quantity;
      state.selectedOrder.gst = state.selectedOrder.subTotal * 0.08;
      state.selectedOrder.total = state.selectedOrder.subTotal + state.selectedOrder.gst;
    },
    updateOrderStatus: (state, action) => {
      const { orderId, statusTemplateId, statusTemplateName, statusTemplateColor } = action.payload;
      const order = state.orders.find(order => order.id === orderId);
      if (order) {
        order.order_status_id = statusTemplateId;
        order.status_template = {
          id: statusTemplateId,
          name: statusTemplateName,
          color: statusTemplateColor
        };
      }
      if (state.selectedOrder.id === orderId) {
        state.selectedOrder.order_status_id = statusTemplateId;
        state.selectedOrder.status_template = {
          id: statusTemplateId,
          name: statusTemplateName,
          color: statusTemplateColor
        };
      }
    },
    setTaxDetails: (state, action) => {
      state.selectedOrder.tax_details = action.payload;
    },
    // New reducer to handle partial updates
    updateOrder: (state, action) => {
      const { actionType, order } = action.payload;
      if (actionType === 'new') {
        state.orders.unshift(order[0]); // Prepend the new order to the beginning of the array
      } else if (actionType === 'update') {
        const index = state.orders.findIndex(o => o.id === order[0].id);
        if (index !== -1) {
          state.orders[index] = order[0];
        }
        if (state.selectedOrder.id === order[0].id) {
          state.selectedOrder = order[0];
        }
      }
    }
  },
});

export const {
  setViewOrder, clearViewOrder, setOrders, clearOrders, setNewOrder, clearNewOrder, setChangeOrder, clearChangeOrder,
  setRegisters, clearRegisters, setSessionID, clearSessionID, setOrderTypes, clearOrderTypes,
  setOrderType, clearOrderType, setSelectedOrder, updateOrderComment, clearSelectedOrder, addProductToOrder, updateOrderStatus, setTaxDetails,
  incrementOrderCounter, updateOrder, setOrderTable, clearOrderTable, setFloors, clearFloors, setActiveSession, clearActiveSession, setActiveRegister, clearActiveRegister,
  setDenominations, clearDenominations, setSalesHistory, clearSalesHistory, setCashInOut, clearCashInOut, setPendingSave,
  setOrdersHistory, clearOrdersHistory, setCurrentSelectedTableRow, clearCurrentSelectedTableRow
} = orderSlice.actions;
export default orderSlice.reducer;
