import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { AUTH_TOKEN, OUTLET_ID, OUTLET_LOGO, AUTH_USER, SOAK_ID, TRANSPORT_KEYS, SOAK_DATA } from 'constants/AuthConstant';
// import FirebaseService from 'services/FirebaseService';
import AuthService from 'services/AuthService';
import { setUser, clearUser } from './userSlice';
import echo from 'services/Echo';
import { clearOutletLogoBase64 } from './outletSlice';
import Notify from 'services/NotificationService';
import { persistor } from '../../store/index'; // or wherever you create the persistor
import { sendHeartbeat } from 'services/HeartbeatService';

export const initialState = {
	loading: false,
	message: '',
	showMessage: false,
	redirect: '',
	token: localStorage.getItem(AUTH_TOKEN) || null
}


export const signIn = createAsyncThunk('login', async (data, { rejectWithValue, dispatch }) => {

	const { email, password } = data;

	const setEchoToken = (token) => {
		if (echo.options) {
			if (echo.options.auth) {
				echo.options.auth.headers['Authorization'] = `Bearer ${token}`
			} else {
				let auth = { headers: { Authorization: `Bearer ${token}`, Accept: 'application/json', } }
				echo.options = { ...echo.options, auth: auth }
			}
		}
	}


	try {

		const response = await AuthService.login({ email, password })
		if (response.type == 'error') {
			Notify(response.type, response.type.charAt(0).toUpperCase() + response.type.slice(1), response.message, 0);
			localStorage.clear();
			return rejectWithValue(response.error)
		} else if (response.type == 'warning') {
			Notify(response.type, response.type.charAt(0).toUpperCase() + response.type.slice(1), response.message, 0);
		}
		const token = response.data.encodedToken;
		const outlet_id = response.data.user.outlets[0].identifier_id;
		localStorage.setItem(AUTH_TOKEN, token);
		localStorage.setItem(OUTLET_ID, outlet_id);
		localStorage.setItem(OUTLET_LOGO, response.data.user.outlets[0].logo);
		localStorage.setItem(SOAK_ID, response.data.soakID);
		localStorage.setItem(TRANSPORT_KEYS, JSON.stringify(response.data.keys));
		localStorage.setItem(SOAK_DATA, JSON.stringify(response.data.soakData));
		setEchoToken(token)	// fix token issue for Echo
		dispatch(setUser(response.data));
		dispatch(clearOutletLogoBase64());
		sendHeartbeat(); // Send heartbeat request on startup
		return token;
	} catch (err) {

		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})
export const signUp = createAsyncThunk('register', async (data, { rejectWithValue }) => {
	const { email, password } = data
	try {
		const response = await AuthService.register({ email, password })
		const token = response.data.token;
		localStorage.setItem(AUTH_TOKEN, token);
		return token;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})


export const signOut = createAsyncThunk('logout', async (dispatch) => {
	const response = await AuthService.logout()
	localStorage.removeItem(AUTH_TOKEN);
	// localStorage.removeItem(AUTH_USER);
	localStorage.removeItem(OUTLET_ID);
	localStorage.removeItem(OUTLET_LOGO);
	localStorage.removeItem(SOAK_ID);
	localStorage.removeItem(TRANSPORT_KEYS);
	localStorage.removeItem(SOAK_DATA);

	// keep sys info and then clear AUTH_USER, this is important for the system version update
	const authUser = JSON.parse(localStorage.getItem(AUTH_USER)); // Retrieve AUTH_USER from localStorage
	const pusherTransportNonTLS = JSON.parse(localStorage.getItem('pusherTransportNonTLS')); // Retrieve pusherTransportNonTLS from localStorage
	if (authUser && authUser.systemInfo) {
		const updatedAuthUser = { systemInfo: authUser.systemInfo }; // Create a new object with only systemInfo
		localStorage.setItem(AUTH_USER, JSON.stringify(updatedAuthUser)); // Store the updated object in localStorage
		localStorage.setItem('pusherTransportNonTLS', JSON.stringify(pusherTransportNonTLS)); // Store the updated object in localStorage
	} else {
		console.log('No AUTH_USER found or systemInfo missing');
	}

	// Clear IndexedDB persistStore from persistedReduxState database
	clearIndexedDB();

	// remove user from redux store
	dispatch(clearUser());
	return response.data
})

const clearIndexedDB = async () => {
	try {
		await persistor.purge(); // Clear persisted Redux store
		console.log('Persisted Redux state cleared successfully.');
	} catch (error) {
		console.error('Failed to clear persisted Redux state:', error);
	}
};


export const changeOutlet = createAsyncThunk('changeOutlet', async (data, { rejectWithValue, dispatch }) => {
	try {
		const response = await AuthService.changeOutlet({ data })
		if (response.type == 'success') {
			localStorage.setItem(OUTLET_ID, response.data.identifier_id);
			localStorage.setItem(OUTLET_LOGO, response.data.logo);
			dispatch(clearOutletLogoBase64())
			window.location.reload();
		} else {
			return rejectWithValue(response.message)
		}
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const signInWithGoogle = createAsyncThunk('signInWithGoogle', async (_, { rejectWithValue }) => {
	try {
		const response = await AuthService.loginInOAuth()
		const token = response.data.token;
		localStorage.setItem(AUTH_TOKEN, token);
		return token;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const signInWithFacebook = createAsyncThunk('signInWithFacebook', async (_, { rejectWithValue }) => {
	try {
		const response = await AuthService.loginInOAuth()
		const token = response.data.token;
		localStorage.setItem(AUTH_TOKEN, token);
		return token;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})


export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		authenticated: (state, action) => {
			state.loading = false
			state.redirect = '/'
			state.token = action.payload
		},
		showAuthMessage: (state, action) => {
			state.message = action.payload
			state.showMessage = true
			state.loading = false
		},
		hideAuthMessage: (state) => {
			state.message = ''
			state.showMessage = false
		},
		signOutSuccess: (state) => {
			state.loading = false
			state.token = null
			state.redirect = '/'
		},
		showLoading: (state) => {
			state.loading = true
		},
		signInSuccess: (state, action) => {
			state.loading = false
			state.token = action.payload
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(signIn.pending, (state) => {
				state.loading = true
			})
			.addCase(signIn.fulfilled, (state, action) => {
				state.loading = false
				state.redirect = '/'
				state.token = action.payload
			})
			.addCase(signIn.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})
			.addCase(signOut.fulfilled, (state) => {
				state.loading = false
				state.token = null
				state.redirect = '/'
			})
			.addCase(signOut.rejected, (state) => {
				state.loading = false
				state.token = null
				state.redirect = '/'
			})
			.addCase(signUp.pending, (state) => {
				state.loading = true
			})
			.addCase(signUp.fulfilled, (state, action) => {
				state.loading = false
				state.redirect = '/'
				state.token = action.payload
			})
			.addCase(signUp.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})
			.addCase(signInWithGoogle.pending, (state) => {
				state.loading = true
			})
			.addCase(signInWithGoogle.fulfilled, (state, action) => {
				state.loading = false
				state.redirect = '/'
				state.token = action.payload
			})
			.addCase(signInWithGoogle.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})
			.addCase(signInWithFacebook.pending, (state) => {
				state.loading = true
			})
			.addCase(signInWithFacebook.fulfilled, (state, action) => {
				state.loading = false
				state.redirect = '/'
				state.token = action.payload
			})
			.addCase(signInWithFacebook.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})
	},
})

export const {
	authenticated,
	showAuthMessage,
	hideAuthMessage,
	signOutSuccess,
	showLoading,
	signInSuccess
} = authSlice.actions

export default authSlice.reducer