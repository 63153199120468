import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ThemeSwitcherProvider } from 'react-css-theme-switcher';
import { store, persistor } from './store';  // Correctly import named exports
import Layouts from './layouts';
import { THEME_CONFIG } from './configs/AppConfig';
import './lang';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
// import processRecords from 'services/OfflineAPIRequestHandler'
import NetworkStatusManager from 'services/NetworkStatusManager';
import { PersistGate } from 'redux-persist/integration/react';
import useSessionSync from 'utils/hooks/useSessionSync';

const themes = {
  dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};

const queryClient = new QueryClient();

function App() {

  return (

    <div className="App">

      <Provider store={store}>
        <PersistGate loading={<div>Loading...</div>} persistor={persistor}>
          <QueryClientProvider client={queryClient}>
            <BrowserRouter>

              <ThemeSwitcherProvider
                themeMap={themes}
                defaultTheme={THEME_CONFIG.currentTheme}
                insertionPoint="styles-insertion-point"
              >

                <NetworkStatusManager />

                <SessionSyncWrapper>
                  <Layouts />
                </SessionSyncWrapper>

              </ThemeSwitcherProvider>
            </BrowserRouter>
          </QueryClientProvider>
        </PersistGate>
      </Provider>

    </div>
  );
}

// Create a wrapper component for session synchronization
function SessionSyncWrapper({ children }) {
  useSessionSync();
  return children;
}


export default App;
